import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import { selectedLocationSelector, isCurrentGPSLocationSelector } from 'Selectors/location';
import { ITEM_SEARCH_RELAXATION_TYPE_BUCKET, SUGGESTED_ITEMS } from 'Constants/items';
import { injectIntl } from 'react-intl';
import {
    itemsFirstSectionOffsetSelector,
    itemsFirstSectionRelaxationTypeSelector,
    suggestedItemsFirstSectionOffsetSelector
} from 'Selectors/items';

import css from './AdsResult.APP_TARGET.scss';
import { getNewSearchResultsTitle } from 'Helpers/listingResultsTitle';

export class AdsResult extends React.Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        label: PropTypes.string,
        term: PropTypes.string,
        labelFlag: PropTypes.bool,
        selectedLocation: PropTypes.object.isRequired,
        isCurrentGPSLocation: PropTypes.bool.isRequired,
        className: PropTypes.string,
        firstSectionOffset: PropTypes.number,
        firstSectionRelaxationType: PropTypes.string,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        itemsMetadata: PropTypes.object,
        marketConfig: PropTypes.object
    }

    static defaultProps = {
        className: '',
        firstSectionOffset: null,
        firstSectionRelaxationType: null,
        itemsMetadata: {}
    }

    getTermInBold() {
        const { label, term } = this.props;
        const startIndex = label.indexOf('$');
        const endIndex = label.indexOf('}');

        return [label.slice(0, startIndex), <b key={ `label-term-${term}` }>&quot;{term}&quot;</b>, label.slice(endIndex + 1)];
    }

    renderNewSearchResultsTitle(title) {
        const { className } = this.props;

        return (
            <p className={ classNames(className, css.label, css.mxLabel) }>
                <span className={ classNames(css.labelText, css.mxLabelTxt, css.unavailableYearTitle) } dangerouslySetInnerHTML={ { __html: title } } />
            </p>
        );
    }

    render() {
        const {
            total,
            label,
            labelFlag,
            selectedLocation,
            className,
            firstSectionOffset,
            firstSectionRelaxationType,
            isCurrentGPSLocation,
            intl,
            itemsMetadata,
            marketConfig
        } = this.props;

        const totalValue = firstSectionOffset === null || firstSectionRelaxationType === ITEM_SEARCH_RELAXATION_TYPE_BUCKET ? total : firstSectionOffset;
        const values = { total: totalValue };
        const location = isCurrentGPSLocation ? intl.formatMessage({ id: 'nearYou' }) : selectedLocation && selectedLocation.name;
        let translationId = firstSectionRelaxationType === ITEM_SEARCH_RELAXATION_TYPE_BUCKET ? 'adsResultsNear' : 'adsResultsIn';

        translationId = isCurrentGPSLocation ? 'adsResultsCurrentLocation' : translationId;

        const adsResultMessage = intl.formatMessage({ id: translationId }, values);

        const showUnavailableYearTitle = marketConfig?.get?.('listing', 'showUnavailableYearTitle');

        const newSearchResultsTitle = getNewSearchResultsTitle(itemsMetadata, showUnavailableYearTitle, intl);

        if (newSearchResultsTitle) {
            return this.renderNewSearchResultsTitle(newSearchResultsTitle);
        }

        // the check is for mobile device
        // as the label will never shown in the SortingContainer/AdResult replacement
        if (labelFlag && !!label) {
            return (
                <p className={ classNames(className, css.label) }>
                    <span className={ css.labelText }>{this.getTermInBold()}</span>
                    {
                        <span className={ css.adCount }>{total}&nbsp;<Translation id="adsCountPrefix" /></span>
                    }
                </p>
            );
        }

        return (
            <p className={ classNames(className) }
                id={ translationId }>{adsResultMessage}&nbsp;<b>{location}</b></p>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const { labelType } = ownProps;

    return {
        selectedLocation: selectedLocationSelector(state),
        isCurrentGPSLocation: isCurrentGPSLocationSelector(state),
        firstSectionOffset: (labelType === SUGGESTED_ITEMS) ? suggestedItemsFirstSectionOffsetSelector(state) : itemsFirstSectionOffsetSelector(state),
        firstSectionRelaxationType: itemsFirstSectionRelaxationTypeSelector(state)
    };
};

export default compose(
    connect(mapStateToProps),
    injectIntl
)(AdsResult);
