import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'Server';
import withTrack from 'HOCs/withTrack/withTrack';
import { urls as configUrls } from 'Reducers/config';
import { configSelector } from 'Selectors/config';
import TanakAppliedFilters from 'Components/Listing/components/TanakAppliedFilters.APP_TARGET';
import css from '../../Listing.APP_TARGET.scss';
import NoResults from 'Components/NoResults/NoResults';
import ListingComponent from 'Components/Listing/Listing';
import ListingContent from '../../components/ListingContent/ListingContent';
import { ITEM_SOURCE } from 'Constants/items';
import { SLIDER_TYPE } from 'Constants/listingSlider';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import ZrpWidget from 'Components/ZrpWidget/ZrpWidget';

export const ListingBody = ({
    adIdsToUpdate,
    categoryID,
    enabledRealImpressions,
    fourColumns,
    isFetchingItems,
    items,
    categoryTree,
    listingHeader,
    onItemClick,
    onLoadNextPage,
    onViewItem,
    originalTotal,
    page,
    parentItems,
    renderBanners,
    sectionsItems,
    listingSliderType,
    showLoadMore,
    source,
    suggestedItemsSections,
    suggestedItemsTotal,
    visualizationType,
    showMXDesign,
    extraTrackAttr,
    itemsMetadata,
    similarAdsData,
    similarAdsCount,
    noResultZrpImageURL,
    config,
    getConfig,
    track,
    listingBodyType,
    renderPagination
}) => {
    useEffect(() => {
        if (!config && getConfig) {
            getConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { zrpWidgetConfig,
        isNewUspVasDesign
    } = config?.buyers?.listing || {};

    if (!isFetchingItems && !items?.length && !similarAdsData?.length && similarAdsCount !== 0) {
        return (
            <>
                <TanakAppliedFilters categoryID={ categoryID } />
                <div className={ css.noResults }>
                    <NoResults />
                </div>
            </>
        );
    }
    const getTitle = () => {
        const headerText = { title: {}, subTitle: {}};

        if (originalTotal) {
            headerText.title.value = <Translation id={ 'someCarsZRP' } />;
            headerText.subTitle.value = <Translation id="recommendedListBelow" />;
        }
        else {
            headerText.title.value = <Translation id={ 'noCarsZRP' } />;
            headerText.subTitle.value = <Translation id="recommendedListBelow" />;
        }

        headerText.title.dataAutId = 'no_result_banner_title';
        headerText.subTitle.dataAutId = 'no_result_banner_sub_title';
        return headerText;
    };
    const MAX_ITEMS = 10;
    const shouldRenderZRPWidgets = (!!similarAdsCount && !!similarAdsData?.length && !!itemsMetadata.total_similar_ads && similarAdsData.length < MAX_ITEMS) && !isFetchingItems;

    return (
        <>
            <ListingComponent
                renderPagination={ renderPagination }
                onLoadNextPage={ onLoadNextPage }
                isFetching={ isFetchingItems }
                enabledRealImpressions={ enabledRealImpressions }
                onViewItem={ onViewItem }
                fourColumns={ fourColumns }
                header={ listingHeader }
                page={ page }
                similarAdsData={ similarAdsData }
                similarAdsCount={ similarAdsCount }
                showLoadMore={ showLoadMore }
                renderBanners={ renderBanners }
                adIdsToUpdate={ adIdsToUpdate }
                itemsLength={ items?.length }
                categoryID={ categoryID }
            >
                <ListingContent
                    items={ items }
                    similarAdsData={ similarAdsData }
                    similarAdsCount={ similarAdsCount }
                    parentItems={ parentItems }
                    categoryTree={ categoryTree }
                    isFetching={ isFetchingItems }
                    enabledRealImpressions={ enabledRealImpressions }
                    fourColumns={ fourColumns }
                    visualizationType={ visualizationType }
                    itemPostPlaceholderOrigin="listing"
                    sectionsItems={ sectionsItems }
                    showMXDesign={ showMXDesign }
                    showLoadMore={ showLoadMore }
                    suggestedItemsSections={ suggestedItemsSections }
                    suggestedItemsTotal={ suggestedItemsTotal }
                    originalTotal={ originalTotal }
                    onViewItem={ onViewItem }
                    onItemClick={ onItemClick }
                    source={ source }
                    listingSliderType={ listingSliderType }
                    showInspectionTag
                    isListingPage
                    extraTrackAttr={ extraTrackAttr }
                    itemsMetadata={ itemsMetadata }
                    categoryID={ categoryID }
                    listingBodyType={ listingBodyType }
                    shouldRenderZRPWidgets={ shouldRenderZRPWidgets }
                    isNewUspVasDesign={ isNewUspVasDesign }
                    isShowVideoTag={ true }// for listing page
                    showSponseredTag={ true }
                    showDealerTag={ true }
                    config={ config }
                    onLoadNextPage={ onLoadNextPage }
                />
            </ListingComponent>
            {((!similarAdsCount && !!similarAdsData?.length && !!itemsMetadata.total_similar_ads)) && !isFetchingItems && <div className={ css.noCarsBannerWrapper }>
                <div className={ css.noCarsBannerImage }>
                    <ImageWrapper
                        fileName={ noResultZrpImageURL }
                        className={ css.timeImg }
                        data-aut-id="zrp_banner_image"
                    />
                </div>
                <div className={ css.noCarsBannerTextWrapper }>
                    <p className={ css.noCarsBannerTitle } data-aut-id="zrp_banner_title">{getTitle().title.value}</p>
                    <p className={ css.noCarsBannerSubTitle } data-aut-id="zrp_banner_subTitle">{getTitle().subTitle.value}</p>
                </div>
            </div>}
            {shouldRenderZRPWidgets && <ZrpWidget zrpWidgetConfig={ zrpWidgetConfig } track={ track } /> }
        </>
    );
};

export const mapStateToProps = state => {
    return {
        config: configSelector(state)
    };
};

const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(get(configUrls.getConfig, 'CONFIG'))
});

ListingBody.propTypes = {
    adIdsToUpdate: PropTypes.array,
    categoryID: PropTypes.string.isRequired,
    enabledRealImpressions: PropTypes.bool,
    fourColumns: PropTypes.bool,
    isFetchingItems: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    categoryTree: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    similarAdsData: PropTypes.arrayOf(PropTypes.object),
    similarAdsCount: PropTypes.number,
    noResultZrpImageURL: PropTypes.string,
    listingHeader: PropTypes.node,
    onItemClick: PropTypes.func,
    onLoadNextPage: PropTypes.func,
    onViewItem: PropTypes.func,
    originalTotal: PropTypes.number,
    page: PropTypes.number.isRequired,
    parentItems: PropTypes.shape({
        locationFrom: PropTypes.string,
        locationTo: PropTypes.string
    }),
    renderBanners: PropTypes.func,
    sectionsItems: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    listingSliderType: PropTypes.oneOf([SLIDER_TYPE.INSPECTION, SLIDER_TYPE.OLX_AUTOS, null]),
    showLoadMore: PropTypes.bool,
    source: PropTypes.oneOf([ITEM_SOURCE.SEARCH, ITEM_SOURCE.BROWSE]),
    suggestedItemsSections: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    suggestedItemsTotal: PropTypes.number,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    showMXDesign: PropTypes.bool,
    extraTrackAttr: PropTypes.object,
    itemsMetadata: PropTypes.object,
    track: PropTypes.func,
    getConfig: PropTypes.func,
    config: PropTypes.object,
    listingBodyType: PropTypes.string,
    renderPagination: PropTypes.func
};

ListingBody.defaultProps = {
    adIdsToUpdate: [],
    enabledRealImpressions: false,
    fourColumns: false,
    isFetchingItems: false,
    items: [],
    originalTotal: 0,
    listingHeader: null,
    similarAdsData: [],
    onItemClick: () => {},
    onLoadNextPage: () => {},
    onViewItem: () => {},
    renderBanners: () => {},
    showLoadMore: false,
    visualizationType: 'grid',
    extraTrackAttr: {},
    itemsMetadata: {},
    listingBodyType: '',
    renderPagination: () => {}
};
export default compose(
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(ListingBody);
