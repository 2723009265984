import React from 'react';
import PropTypes from 'prop-types';
import PaginationUI from 'Components/PaginationV2/PaginationV2';

export const Pagination = ({ 'data-aut-id': dataAutId, total, onPageChanged, currentPage, pathname, ...rest }) => {
    return (
        <PaginationUI
            { ...rest }
            total={ total }
            data-aut-id={ dataAutId }
            onPageChanged={ onPageChanged }
            currentPage={ currentPage }
            pathname={ pathname }
        />
    );
};

Pagination.propTypes = {
    'data-aut-id': PropTypes.string,
    total: PropTypes.number,
    onPageChanged: PropTypes.func,
    currentPage: PropTypes.number,
    pathname: PropTypes.string
};

Pagination.defaultProps = {
    'data-aut-id': 'pagination'
};

export default Pagination;
